export const EnableLogs = false

/*  export const URL = 'https://sapp.mycw.in/services/graphql'  // PreProductionServer URL
export const FILEDOWNLOADURL = 'https://sapp.mycw.in:8443/'  */

/* export const URL = 'https://s20q1.mycw.in/back-office-2.0/graphql'
 export const FILEDOWNLOADURL = 'https://s20q1.mycw.in:8443/' */

  export const URL = 'https://sdemos.cw.solutions/back-office-2.0/graphql'
  export const FILEDOWNLOADURL = 'https://sdemos.cw.solutions:8443/'
